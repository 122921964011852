<template>
  <component
    v-bind="$attrs"
    :is="is ?? 'div'"
    :class="classNames.root"
    :aria-labelledby="props.ariaLabelledBy ? undefined : labelledBy"
  >
    <div v-bind="attributes?.wrapper" :class="classNames.wrapper">
      <slot name="image">
        <UiSkeleton
          v-if="canShowImage"
          :loading
          :classes="{
            container: 'h-fit w-fit',
            slot: cn(
              'flex h-full flex-none flex-col items-center justify-center bg-white',
              { 'bg-neutral-200': !image && canShowImage },
              classNames.image
            ),
            line: [classNames.image, 'bg-skeleton border-none'],
          }"
        >
          <div v-if="image && canShowImage">
            <UiNuxtIcon
              v-if="isSvg"
              v-bind="attributes?.image"
              :name="image!"
              :alt="imageAlt!"
              :size="imageSize"
              filled
              :class="classNames.image"
            />
            <NuxtImg
              v-else
              v-bind="attributes?.image"
              :src="fallbackImage && imageError ? fallbackImage : image"
              :alt="imageAlt || undefined"
              transition="none"
              @error="() => (imageError = true)"
            />
          </div>
        </UiSkeleton>
      </slot>

      <UiSkeleton
        v-bind="attributes?.titlesSkeletonLine"
        :loading
        :repeat="skeletonsRepeat"
        :classes="classNames.skeleton"
      >
        <div
          v-if="loading || canShowTitle || canShowSubtitle"
          v-bind="attributes?.titlesWrapper"
          :class="classNames.titlesWrapper"
        >
          <slot v-bind="{ labelledBy }" name="title">
            <UiTransition v-if="canShowTitle" name="fade" mode="out-in">
              <component
                v-bind="attributes?.title"
                :is="titleTag ?? 'span'"
                ui-info-title
                :class="classNames.title"
                :id="labelledBy"
                :key="title"
              >
                {{ title }}
              </component>
            </UiTransition>
          </slot>

          <slot name="subtitle">
            <UiTransition v-if="canShowSubtitle" name="fade" mode="out-in">
              <p v-bind="attributes?.subtitle" ui-info-subtitle :class="classNames.subtitle" :key="subtitle!">
                {{ subtitle }}
              </p>
            </UiTransition>
          </slot>
        </div>
      </UiSkeleton>

      <slot />
    </div>
  </component>
</template>

<script lang="ts">
import { VariantProps, cva } from "class-variance-authority"
import { type ClassValue } from "clsx"

import { UiSkeletonClasses } from "@finq/ui/components/ui/Skeleton.vue"

import { UiClassesToAttributes } from "../../types/common"

const infoVariants = cva("rounded-sm", {
  variants: {
    imageSize: {
      xxs: "w-xxs h-xxs",
      xs: "w-xs h-xs",
      s: "w-s h-s",
      24: "w-6 h-6",
      m: "w-m h-m",
      mx: "w-mx h-mx",
      l: "w-16 h-16",
      xl: "w-xl h-xl",
    },
  },
  defaultVariants: {
    imageSize: "m",
  },
})

export interface UiInfoClasses {
  root: ClassValue
  title: ClassValue
  subtitle: ClassValue
  image: ClassValue
  wrapper: ClassValue
  titlesWrapper: ClassValue
  titlesSkeletonLine?: ClassValue
  titlesSkeletonSlot?: ClassValue
  // todo)) Change all `titlesSkeletonLine` implementations to `classes.skeleton.line`
  skeleton: Partial<UiSkeletonClasses>
}

export interface UiInfoProps {
  is?: string
  unsetAll?: boolean
  classes?: Partial<UiInfoClasses>
  attributes?: UiClassesToAttributes<UiInfoClasses>
  skeletonsRepeat?: number
  ariaLabelledBy?: string

  // Loading
  loading?: boolean

  // Image
  image?: string | null
  fallbackImage?: string
  imageAlt?: string | null
  imageSize?: VariantProps<typeof infoVariants>["imageSize"] | number
  hasImage?: boolean // Useful when you need to define skeletons ahead of time

  // Title
  title?: string | null
  titleTag?: keyof HTMLElementTagNameMap
  titleClasses?: string
  hasTitle?: boolean // Useful when you need to define skeletons ahead of time
  clamp?: boolean

  // Subtitle
  subtitle?: string | null
  subtitleClasses?: string
  hasSubtitle?: boolean // Useful when you need to define skeletons ahead of time

  // LTR
  forceLtrTitle?: boolean
}
</script>

<script setup lang="ts">
const slots = useSlots()
const id = useId()

const props = withDefaults(defineProps<UiInfoProps>(), {
  imageSize: "mx",
  skeletonsRepeat: 1,
  clamp: false,
  loading: undefined,
})

const imageError = ref(false)

const classNames = computed(
  (): Omit<Required<UiInfoClasses>, "titlesSkeletonLine" | "titlesSkeletonSlot"> => {
    return {
      root: cn("w-full leading-none", props.classes?.root),
      wrapper: cn(
        "flex w-full items-center",
        { "gap-xs": canShowImage.value || canShowTitle.value || canShowSubtitle.value },
        props.classes?.wrapper
      ),
      title: cn("font-semibold", props.classes?.title, {
        "line-clamp-1": props.clamp,
        "force-ltr-title": props.forceLtrTitle,
      }),
      subtitle: cn("text-black/50", { "line-clamp-1": props.clamp }, props.classes?.subtitle),
      titlesWrapper: cn("flex flex-1 flex-col gap-1", props.classes?.titlesWrapper),
      image: cn(
        infoVariants({ imageSize: props.imageSize as VariantProps<typeof infoVariants>["imageSize"] }),
        props.classes?.image
      ),

      skeleton: {
        ...props.classes?.skeleton,
        line: cn(
          "h-4 w-1/2 rounded-md",
          props.classes?.titlesSkeletonLine, // TODO)) remove this when refactoring to `classes?.skeleton`
          props.classes?.skeleton?.line
        ),
        slot: cn(
          props.classes?.titlesSkeletonSlot, // TODO)) remove this when refactoring to `classes?.skeleton`
          props.classes?.skeleton?.slot
        ),
      },
    }
  }
)

const labelledBy = computed(() => props.ariaLabelledBy ?? "ui-card-title-" + id)

const hasTitleSlot = computed(() => !isSlotEmpty(slots.title, props))
const hasSubtitleSlot = computed(() => !isSlotEmpty(slots.subtitle, props))
const hasImageSlot = computed(() => !isSlotEmpty(slots.image, props))

const canShowImage = computed(() => props.hasImage || Boolean(props.image) || hasImageSlot.value)
const canShowTitle = computed(() => props.hasTitle || Boolean(props.title) || hasTitleSlot.value)
const canShowSubtitle = computed(() => props.hasSubtitle || Boolean(props.subtitle) || hasSubtitleSlot.value)

const isSvg = computed(() => !props.image?.match(/\.(jpeg|gif|jpg|png|webp)$/i))
</script>

<style lang="scss" scoped>
.force-ltr-title {
  direction: ltr;
  .app-rtl & {
    text-align: end;
  }
}
</style>
